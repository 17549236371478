<template>
  <header class="header d-flex justify-content-between w-100">
    <img class="logo" src="./assets/images/svg/logo.svg" alt="logo-svg" />
    <img
      class="logo-mobile"
      src="./assets/images/svg/logo.svg"
      alt="logo-svg"
    />
  </header>

  <section class="main">
    <div class="container">
      <div class="main--container">
        <div class="main_inner-text">
          <h2 class="heading_md font_500 color_primary">
            {{ $t("outsourcing_var") }}
          </h2>
          <h1 class="heading_xxl color_primary">{{ $t("heading") }}</h1>
          <p class="main_text color_main heading_ssm">{{ $t("main_text") }}</p>

          <a href="https://calendly.com/valeryan-brunin" target="_blank" class="main-button heading_ssm">
            <span class="label">{{ $t("main_button") }}</span>
            <span class="icon">
              <span></span>
            </span>
            <span class="icon2"> </span>
          </a>
        </div>
        <div class="image-container">
          <picture class="main-img">
            <source srcset="./assets/images/main.webp" type="image/webp" />
            <img
              id="mainImage"
              :src="mainImageUrl"
              alt="main-image"
              @load="updateBigSquarePosition"
            />
          </picture>
        </div>
      </div>
    </div>

    
  </section>

  <section class="about ">
    <div class="container">
     
        <div class="about_text row">
          <div class="about_text--first col-7">
            <h2
              v-html="$t('about_heading')"
              class="heading_xxl color_primary section"
            ></h2>
            <div class="about_switcher-inner">


              <div class="custom-radio-buttons left" :class="{ 'left': state == 'on', 'right': state == 'off'}">
                <div class="radio--item" @click="switcher = 'on'" id="switcherOn">
                    <input v-model="state" type="radio" id="on" value="on">
                    <label for="on" class="label_left">{{ $t("switcher_on") }}</label>
                </div>
                <div class="radio--item" @click="switcher = 'off'" id="switcherOff">
                    <input v-model="state" type="radio" id="off" value="off">
                    <label for="off" class="label_right">{{ $t("switcher_off") }}</label>
                </div>
              </div>


            <Transition>
              <img
                id="squareImage"
                class="square-image"
                v-show="switcher === 'on'"
                src="./assets/images/svg/square.svg"
                alt="square-image"
              />
            </Transition>
              


            </div>

          </div>


          

        <Transition mode="out-in">
            <div
            class="about_text--second content col-4"
            v-if="switcher === 'on'"
            :key="'on'"
            >
              <p class="heading_ssm color_main about_description text-animated">
                {{ $t("about_text") }}
              </p>
              <img
                class="big-square"
                src="./assets/images/about/square.svg"
                :style="{ right: bigSquareRight }"
                alt="big-square-image"
              />
            </div>
            <div
              class="about_text--second content col-5"
              v-else
              :key="'off'"
            >
              <p class="heading_ssm color_main about_description text-animated">
                {{ $t("about_text2") }}
              </p>
              <img
                class="question-image"
                src="./assets/images/about/question.svg"
                alt="big-question-image"
              />
            </div>
        </Transition>
            


            
      
          
         
       

          

        </div>
     
    </div>
  </section>

  <section class="advantages">
    <div class="container">
      <div class="advantages_inner">
        <div class="advantages_inner-box">
          <h2 class="heading_xxl color_primary section">
            {{ $t("advantages_title") }}
          </h2>
          <p class="heading_ssm color_main text-animated">{{ $t("advantages_subtitle") }}</p>
        </div>
        <div class="advantages_list--desktop list">
          <div
            class="list_box"
            v-for="(item, index) in listItems"
            :key="index"
            ref="cards"
          >
            <h4 class="heading_ssm">{{ $t(item.titleKey) }}</h4>
            <p class="text_lg color_main">{{ $t(item.textKey) }}</p>
          </div>
        </div>
      </div>

      <img
        class="rectangles-image"
        src="./assets/images/advantages/rectangles.png"
        alt="rectangles-image"
      />
    </div>
  </section>

  <section class="quote " id="qoute">
    <div class="container">
      <p class="quote_text text-animated heading_xl color_primary">
        <span><img src="./assets/images/svg/quotes.svg" alt="" /></span>
        {{ $t("quote_text") }}
        <span><img src="./assets/images/svg/quotes.svg" alt="" /></span>
      </p>
    </div>
  </section>

  <section class="features ">
    <div class="container">
      <h2 class="heading_xxl color_primary section">{{ $t("features_title") }}</h2>
    </div>
    <div class="features_list-desktop list">
      <div class="item">
        <img src="./assets/images/svg/cube.svg" alt="cube-img" />
      </div>
      <div class="item">
        <img src="./assets/images/svg/cube.svg" alt="cube-img" />
      </div>
      <div class="item">
        <img src="./assets/images/svg/cube.svg" alt="cube-img" />
      </div>
      <div class="item text">
        <h4 class="heading_md color_primary">{{ $t("list_title7") }}</h4>
        <p class="color_main text_lg">{{ $t("list_text7") }}</p>
      </div>
      <div class="item">
        <img src="./assets/images/svg/cube.svg" alt="cube-img" />
      </div>
      <div class="item">
        <img src="./assets/images/svg/cube.svg" alt="cube-img" />
      </div>
      <div class="item">
        <img src="./assets/images/svg/cube.svg" alt="cube-img" />
      </div>
      <div class="item text">
        <h4 class="heading_md color_primary">{{ $t("list_title8") }}</h4>
        <p class="color_main text_lg">{{ $t("list_text8") }}</p>
      </div>
      <div class="item">
        <img src="./assets/images/svg/cube.svg" alt="cube-img" />
      </div>
      <div class="item">
        <img src="./assets/images/svg/cube.svg" alt="cube-img" />
      </div>
      <div class="item">
        <img src="./assets/images/svg/cube.svg" alt="cube-img" />
      </div>
      <div class="item">
        <img src="./assets/images/svg/cube.svg" alt="cube-img" />
      </div>
      <div class="item">
        <img src="./assets/images/svg/cube.svg" alt="cube-img" />
      </div>
      <div class="item">
        <img src="./assets/images/svg/cube.svg" alt="cube-img" />
      </div>
      <div class="item text">
        <h4 class="heading_md color_primary">{{ $t("list_title9") }}</h4>
        <p class="color_main text_lg">{{ $t("list_text9") }}</p>
      </div>

      <div class="item">
        <img src="./assets/images/svg/cube.svg" alt="cube-img" />
      </div>
      <div class="item text">
        <h4 class="heading_md color_primary">{{ $t("list_title10") }}</h4>
        <p class="color_main text_lg">{{ $t("list_text10") }}</p>
      </div>
      <div class="item">
        <img src="./assets/images/svg/cube.svg" alt="cube-img" />
      </div>
    </div>
  </section>

  <section class="projects ">
    <div class="container">
      <h2 class="heading_xxl color_primary section">{{ $t("projects_title") }}</h2>
      <div class="projects_inner">
        <div
          v-for="(project, index) in projects"
          :key="index"
          class="projects_project project"
        >
          <div class="project_box">
            <h3 class="heading_xl color_primary">{{ project.title }}</h3>
            <span class="text_sm color_secondary">{{ project.subtitle }}</span>
            <p class="heading_ssm color_main">{{ project.description }}</p>
            <button class="button_more" @click="openModal(index)">
              {{ $t("btn_more") }}
            </button>
          </div>

          <div class="project_img">
            <img :src="project.image" :alt="project.title + '-img'" />
          </div>

          <Transition name="slide-fade">
            <AppModalVue
              :class="{ active: modalVisible }"
              v-if="modalVisible && selectedProject === index"
              :selectedProject="selectedProject"
              @close-modal="closeModal"
            />
          </Transition>
        </div>
      </div>
    </div>
  </section>

  <section class="process">
    <div class="container">
      <h2 class="heading_xxl color_primary section">{{ $t("process_title") }}</h2>
      <div class="process_inner">
        <div class="process_content">
          <div class="process_box">
            <h3 class="heading_md color_primary">
              {{ $t("process_step_1_title") }}
            </h3>
            <p class="heading_ssm color_main">{{ $t("process_step_1") }}</p>
            <p class="heading_ssm color_main timeline">
              {{ $t("process_step_1_timeline") }}
            </p>

            <div class="process_img--mob">
              <img
                src="./assets/images/process/layer1Mob.svg"
                alt="layer-img"
              />
            </div>
          </div>
          <div class="process_box">
            <h3 class="heading_md color_primary">
              {{ $t("process_step_2_title") }}
            </h3>
            <p class="heading_ssm color_main">{{ $t("process_step_2") }}</p>
            <p class="heading_ssm color_main timeline">
              {{ $t("process_step_2_timeline") }}
            </p>
            <div class="process_img--mob">
              <img
                src="./assets/images/process/layer2Mob.svg"
                alt="layer-img"
              />
            </div>
          </div>
          <div class="process_box">
            <h3 class="heading_md color_primary">
              {{ $t("process_step_3_title") }}
            </h3>
            <p class="heading_ssm color_main">{{ $t("process_step_3") }}</p>
            <p class="heading_ssm color_main timeline">
              {{ $t("process_step_3_timeline") }}
            </p>
            <div class="process_img--mob">
              <img
                src="./assets/images/process/layer3Mob.svg"
                alt="layer-img"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="process_img-inner img">
      
        <img class="process_img" src="./assets/images/process/layer3.svg" alt="layer-image">
        <img class="process_img" src="./assets/images/process/layer2.svg" alt="layer-image">
        <img class="process_img" src="./assets/images/process/layer1.svg" alt="layer-image">
      
    </div>
  </section>

  <section class="quote_second text-animation quote">
    <div class="container">
      <p class="quote_text text-animated heading_xl color_primary">
        <span><img src="./assets/images/svg/quotes.svg" alt="" /></span>
        {{ $t("quote_text_second") }}
        <span><img src="./assets/images/svg/quotes.svg" alt="" /></span>
      </p>
      <small>
        ZOO BIT MIND LABS<br>
        NIP: 5214046321<br>
        Legal address: Warsaw (02-672),<br>
        Domaniewska St., No. 37, premises 2.43
      </small>
    </div>
  </section>

  <div class="footer_quote section">
    <div class="container">
      <h2>{{ $t("footer_quote") }}</h2>
    </div>
  </div>



  <footer class="footer">
    <div class="container">
      <div class="footer_inner">
        <h3 class="heading_ssm">{{ $t("footer_title") }}</h3>
        <a href="https://calendly.com/valeryan-brunin" target="_blank" class="main-button heading_ssm main-button--dark">
          <span class="label">{{ $t("main_button") }}</span>
          <span class="icon">
            <span></span>
          </span>
          <span class="icon2"> </span>
        </a>
      </div>
    </div>
  </footer>

  <a class="book_button text_lg" target="_blank" href="https://calendly.com/valeryan-brunin">{{ $t("book_btn") }}</a>
</template>

<script>
import AppModalVue from "./components/AppModal.vue";
import gsap from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
gsap.registerPlugin(ScrollTrigger);

export default {
  name: "App",
  components: {
    AppModalVue,
  },
  data() {
    return {
      mainImageUrl: "./assets/images/main.png",
      bigSquareRight: 0,
      switcher: "on",
      modalVisible: false,
      selectedProject: null,
      state: ''
    };
  },
  computed: {
    projects() {
      return [
        {
          title: "How to Move",
          subtitle: this.$t("project_subtitle1"),
          description: this.$t("project_desc1"),
          image: "/projects/htm.jpg",
        },
        {
          title: "GTE",
          subtitle: this.$t("project_subtitle2"),
          description: this.$t("project_desc2"),
          image: "/projects/gte.jpg",
        },
        {
          title: "GIT",
          subtitle: this.$t("project_subtitle1"),
          description: this.$t("project_desc3"),
          image: "/projects/git.jpg",
        },
      ];
    },
    listItems() {
      return [
        { titleKey: this.$t("list_title1"), textKey: this.$t("list_text1") },
        { titleKey: this.$t("list_title2"), textKey: this.$t("list_text2") },
        { titleKey: this.$t("list_title3"), textKey: this.$t("list_text3") },
        { titleKey: this.$t("list_title4"), textKey: this.$t("list_text4") },
        { titleKey: this.$t("list_title5"), textKey: this.$t("list_text5") },
        { titleKey: this.$t("list_title6"), textKey: this.$t("list_text6") },
      ];
    },
  },
  methods: {
    updateBigSquarePosition() {
        const mainImage = document.getElementById("mainImage");

        const mainImageWidth = mainImage.offsetWidth;
        const offset = mainImageWidth * 0.5;

        this.bigSquareRight = `${offset}px`;
      },

    openModal(index) {
      this.selectedProject = index;
      this.modalVisible = true;
      document.body.classList.add("no-scroll");
    },
    closeModal() {
      this.modalVisible = false;
      this.selectedProject = null;
      document.body.classList.remove("no-scroll");
    },
  },
  mounted() {
    this.updateBigSquarePosition();
  },
};
</script>

<style lang="sass">
@import "@/assets/styles/main.sass"
</style>


